import React, { useEffect, useState } from 'react';
import { useParams, useLocation, Link, useNavigate } from 'react-router-dom';
import { Row, Col, Card, Spinner, Alert, Breadcrumb } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import slugify from 'slugify';
import './PetDetail.css';

// Helper: convert a string to a URL-friendly slug
const toSlug = (str) =>
    slugify(str, { lower: true });

// Helper: find original value from slug
const findOriginalValue = (slug, candidates = []) => {
    if (!slug) return null;
    return candidates.find((val) => toSlug(val) === slug);
};

const FindPetsDetail = () => {
    const { lang, countrySlug, regionSlug, citySlug } = useParams();
    const [adverts, setAdverts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [locationTree, setLocationTree] = useState(null);
    const [loadingLocationTree, setLoadingLocationTree] = useState(true);

    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    // Fetch the location tree for slug-to-name conversion
    useEffect(() => {
        setLoadingLocationTree(true);
        fetch('https://us-central1-petshome-da28b.cloudfunctions.net/api/public/pets/locations')
            .then((res) => {
                if (!res.ok) throw new Error('Failed to load location data');
                return res.json();
            })
            .then((data) => {
                setLocationTree(data);
                setLoadingLocationTree(false);
            })
            .catch((error) => {
                console.error('Error loading location tree:', error);
                setError('Failed to load location data. Please try again later.');
                setLoadingLocationTree(false);
            });
    }, []);

    // Convert slugs to actual values
    let countryName, regionName, cityName;
    if (locationTree && countrySlug) {
        const countries = Object.keys(locationTree);
        countryName = findOriginalValue(countrySlug, countries);

        if (countryName && regionSlug) {
            const regions = Object.keys(locationTree[countryName] || {});
            regionName = findOriginalValue(regionSlug, regions);

            if (regionName && citySlug && locationTree[countryName][regionName]) {
                const cities = locationTree[countryName][regionName];
                cityName = findOriginalValue(citySlug, cities);
            }
        }

        // If slugs don't match actual values, redirect to the correct URL
        if (countryName && !regionSlug) {
            // Only country provided, this is fine
        } else if (countryName && regionName && !citySlug) {
            // Country and region provided, this is fine
        } else if (countryName && regionName && cityName) {
            // All provided and valid, this is fine
        } else {
            // Invalid combination, redirect to home or show error
            setError('The requested location could not be found.');
        }
    }

    // Build query parameters for adverts API
    let queryParams = '';
    if (countryName) queryParams += `&countryName=${encodeURIComponent(countryName)}`;
    if (regionName) queryParams += `&region=${encodeURIComponent(regionName)}`;
    if (cityName) queryParams += `&city=${encodeURIComponent(cityName)}`;

    // Fetch adverts
    useEffect(() => {
        if (!loadingLocationTree && (locationTree || error)) {
            setLoading(true);

            // Only fetch if we have valid location data or if showing country-level data
            if (!error || (countrySlug && !regionSlug && !citySlug)) {
                fetch(`https://us-central1-petshome-da28b.cloudfunctions.net/api/public/pets/all?allAds=true${queryParams}`)
                    .then((res) => {
                        if (!res.ok) throw new Error('Failed to fetch pet listings');
                        return res.json();
                    })
                    .then((data) => {
                        setAdverts(Array.isArray(data) ? data : []);
                        setLoading(false);
                    })
                    .catch((err) => {
                        console.error('Error fetching adverts:', err);
                        setError('Failed to load pet listings. Please try again later.');
                        setLoading(false);
                    });
            } else {
                setLoading(false);
            }
        }
    }, [loadingLocationTree, locationTree, queryParams, error, countrySlug, regionSlug, citySlug]);

    // Location string for display and meta tags - more structured for SEO
    const locationParts = [];
    if (cityName || citySlug) locationParts.push(cityName || citySlug);
    if (regionName || regionSlug) locationParts.push(regionName || regionSlug);
    if (countryName || countrySlug) locationParts.push(countryName || countrySlug);

    const locationString = locationParts.join(', ');
    const breadcrumbString = locationParts.reverse().join(' > ');

    // Count pets by type for enhanced meta description
    const petTypeCount = adverts.reduce((acc, ad) => {
        const petType = ad.pet_type || 'Other';
        acc[petType] = (acc[petType] || 0) + 1;
        return acc;
    }, {});

    // Create a summary of available pet types for meta description
    const petTypeSummary = Object.entries(petTypeCount)
        .map(([type, count]) => `${count} ${type}${count !== 1 ? 's' : ''}`)
        .join(', ');

    // SEO title and meta description
    let pageTitle = 'Find Pets by Location | PetsHome';
    let metaDescription = 'Browse pets available for adoption, rescue, and rehoming.';

    if (locationString) {
        pageTitle = `Pets for Adoption in ${locationString} | PetsHome`;
        metaDescription = `Find pets for adoption, rescue, and rehoming in ${locationString}.`;

        if (petTypeSummary) {
            metaDescription += ` Available now: ${petTypeSummary}`;
        }
    }

    // Build the canonical URL
    const canonicalPath = location.pathname;
    const canonicalUrl = `https://www.petshome.app${canonicalPath}`;

    // Structured data for SearchAction
    const structuredDataSearchAction = {
        "@context": "https://schema.org",
        "@type": "WebSite",
        "url": "https://www.petshome.app/",
        "potentialAction": {
            "@type": "SearchAction",
            "target": "https://www.petshome.app/search?q={search_term_string}",
            "query-input": "required name=search_term_string"
        }
    };

    // Show a spinner while loading
    if (loading || loadingLocationTree) {
        return (
            <>
                <Helmet>
                    <title>Loading Pet Listings | PetsHome</title>
                    <meta name="robots" content="noindex" />
                </Helmet>
                <div className="text-center my-5">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading pet listings...</span>
                    </Spinner>
                </div>
            </>
        );
    }

    // Show error message if there's an error
    if (error) {
        return (
            <>
                <Helmet>
                    <title>Error | PetsHome</title>
                    <meta name="robots" content="noindex" />
                </Helmet>
                <div className="container my-4">
                    <Alert variant="danger">{error}</Alert>
                </div>
            </>
        );
    }

    return (
        <>
            {/* SEO Meta Tags */}
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={metaDescription} />
                <link rel="canonical" href={canonicalUrl} />

                {/* Open Graph Tags */}
                <meta property="og:title" content={pageTitle} />
                <meta property="og:description" content={metaDescription} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={canonicalUrl} />
                {adverts.length > 0 && adverts[0].main_image && (
                    <meta property="og:image" content={adverts[0].main_image} />
                )}

                {/* Twitter Card Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={pageTitle} />
                <meta name="twitter:description" content={metaDescription} />

                {/* Geo Meta Tags for enhanced location SEO */}
                {countryName && <meta name="geo.country" content={countryName} />}
                {regionName && <meta name="geo.region" content={regionName} />}
                {cityName && <meta name="geo.placename" content={cityName} />}

                {/* Structured data */}
                <script type="application/ld+json">
                    {JSON.stringify(structuredDataSearchAction)}
                </script>
            </Helmet>

            <div className="container my-4 FindPetsDetail">
                {/* Breadcrumbs Navigation */}
                <Breadcrumb className="location-breadcrumb mb-3">
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/${lang}` }}>
                        Home
                    </Breadcrumb.Item>

                    <Breadcrumb.Item
                        linkAs={Link}
                        linkProps={{ to: `/${lang}/pet-classified-ads` }}
                    >
                        Pet Classified Ads
                    </Breadcrumb.Item>

                    {countrySlug && (
                        <Breadcrumb.Item
                            linkAs={Link}
                            linkProps={{ to: `/${lang}/pet-classified-ads/${countrySlug}` }}
                            active={!regionSlug}
                        >
                            {countryName || countrySlug}
                        </Breadcrumb.Item>
                    )}

                    {regionSlug && (
                        <Breadcrumb.Item
                            linkAs={Link}
                            linkProps={{
                                to: `/${lang}/pet-classified-ads/${countrySlug}/${regionSlug}`
                            }}
                            active={!citySlug}
                        >
                            {regionName || regionSlug}
                        </Breadcrumb.Item>
                    )}

                    {citySlug && (
                        <Breadcrumb.Item active>
                            {cityName || citySlug}
                        </Breadcrumb.Item>
                    )}
                </Breadcrumb>

                <header className="mb-4">
                    <h1 className="h1 text-dark">
                        {locationString
                            ? `Pets for Adoption in ${locationString}`
                            : 'Find Pets for Adoption Near You'
                        }
                    </h1>
                    {petTypeSummary && (
                        <p className="lead">Available now: {petTypeSummary}</p>
                    )}
                    <p className="tagline">Adopt, Rehome &amp; Rescue Your Perfect Companion</p>
                </header>

                {adverts.length === 0 ? (
                    <Alert variant="info">
                        <h2 className="h5">No pets currently available in this location</h2>
                        <p>Check back soon or expand your search to nearby areas.</p>
                        {countryName && regionName && cityName && (
                            <Link
                                to={`/${lang}/pet-classified-ads/${countrySlug}/${regionSlug}`}
                                className="btn btn-outline-primary mt-2"
                            >
                                View pets in {regionName}
                            </Link>
                        )}
                        {countryName && regionName && (
                            <Link
                                to={`/${lang}/pet-classified-ads/${countrySlug}`}
                                className="btn btn-outline-primary mt-2 ms-2"
                            >
                                View all pets in {countryName}
                            </Link>
                        )}
                    </Alert>
                ) : (
                    <>
                        <section aria-labelledby="pet-listings">
                            <h2 id="pet-listings" className="visually-hidden">Pet Listings</h2>
                            <Row xs={1} md={2} lg={3} className="g-4">
                                {adverts.map((ad) => {
                                    // Build detail URL - use location data from the ad if available
                                    const adCountrySlug = ad.countryName ? toSlug(ad.countryName) : countrySlug;
                                    const adRegionSlug = ad.region ? toSlug(ad.region) : regionSlug;
                                    const adCitySlug = ad.city ? toSlug(ad.city) : citySlug;
                                    const adSlug = toSlug(ad.title || 'no-title');
                                    const detailUrl = `/${lang}/pet-classified-ads/${adCountrySlug}/${adRegionSlug}/${adCitySlug}/${adSlug}/${ad.id}`;

                                    // Create descriptive alt text
                                    const altText = `${ad.pet_breed || 'Pet'} - ${ad.title} available for adoption in ${ad.city || cityName || ''}`;

                                    // Location display string
                                    const adLocationDisplay = [
                                        ad.city || cityName,
                                        ad.region || regionName,
                                        ad.countryName || countryName
                                    ].filter(Boolean).join(', ');

                                    return (
                                        <Col key={ad.id}>
                                            <article className="h-100">
                                                <Card className="pet-card h-100 shadow-sm">
                                                    {ad.main_image && (
                                                        <div className="position-relative">
                                                            <Card.Img
                                                                variant="top"
                                                                src={ad.main_image}
                                                                alt={altText}
                                                                className="pet-image"
                                                                loading="lazy"
                                                                width="100%"
                                                                height="auto"
                                                            />
                                                            {ad.pet_type && (
                                                                <span className="position-absolute top-0 end-0 badge bg-primary m-2">
                                                                    {ad.pet_type}
                                                                </span>
                                                            )}
                                                        </div>
                                                    )}
                                                    <Card.Body>
                                                        <h3 className="h5 mb-2">
                                                            <Link
                                                                to={detailUrl}
                                                                className="text-decoration-none text-primary"
                                                            >
                                                                {ad.title}
                                                            </Link>
                                                        </h3>
                                                        <p className="mb-1">
                                                            <strong>Breed:</strong> {ad.pet_breed || 'Unknown'}
                                                        </p>
                                                        <p className="mb-1">
                                                            <strong>Location:</strong> {adLocationDisplay}
                                                        </p>
                                                        <p className="mb-2 pet-description">
                                                            {ad.description
                                                                ? ad.description.substring(0, 80) + '...'
                                                                : 'No description available for this pet.'}
                                                        </p>
                                                        <Link
                                                            to={detailUrl}
                                                            className="btn btn-sm btn-outline-primary"
                                                            aria-label={`View details for ${ad.title}`}
                                                        >
                                                            View Details
                                                        </Link>
                                                    </Card.Body>
                                                </Card>
                                            </article>
                                        </Col>
                                    );
                                })}
                            </Row>
                        </section>

                        {/* Schema.org JSON-LD structured data for product listings */}
                        <script type="application/ld+json">
                            {JSON.stringify({
                                "@context": "https://schema.org",
                                "@type": "ItemList",
                                "itemListElement": adverts.map((ad, index) => ({
                                    "@type": "ListItem",
                                    "position": index + 1,
                                    "item": {
                                        "@type": "Product",
                                        "name": ad.title,
                                        "description": ad.description || `${ad.pet_breed || 'Pet'} available for adoption`,
                                        "image": ad.main_image,
                                        "category": ad.pet_type,
                                        "offers": {
                                            "@type": "Offer",
                                            "availability": "https://schema.org/InStock",
                                            "price": ad.asking_price || "0",
                                            "priceCurrency": ad.currency || "USD"
                                        },
                                        "brand": {
                                            "@type": "Brand",
                                            "name": ad.pet_breed || "Mixed Breed"
                                        },
                                        "locationCreated": {
                                            "@type": "Place",
                                            "address": {
                                                "@type": "PostalAddress",
                                                "addressCountry": ad.countryName || countryName,
                                                "addressRegion": ad.region || regionName,
                                                "addressLocality": ad.city || cityName
                                            }
                                        }
                                    }
                                }))
                            })}
                        </script>

                        {/* Local Business structured data for the rescue/adoption service */}
                        <script type="application/ld+json">
                            {JSON.stringify({
                                "@context": "https://schema.org",
                                "@type": "LocalBusiness",
                                "name": "PetsHome Pet Adoption",
                                "description": `Pet adoption service in ${locationString}`,
                                "url": canonicalUrl,
                                "sameAs": [
                                    "https://www.facebook.com/petshomeapp",
                                    "https://www.instagram.com/petshomeapp"
                                ],
                                "address": {
                                    "@type": "PostalAddress",
                                    "addressCountry": countryName,
                                    "addressRegion": regionName,
                                    "addressLocality": cityName
                                },
                                "geo": {
                                    "@type": "GeoCoordinates"
                                },
                                "openingHoursSpecification": {
                                    "@type": "OpeningHoursSpecification",
                                    "dayOfWeek": [
                                        "Monday", "Tuesday", "Wednesday",
                                        "Thursday", "Friday", "Saturday", "Sunday"
                                    ],
                                    "opens": "00:00",
                                    "closes": "23:59"
                                }
                            })}
                        </script>
                    </>
                )}
            </div>
        </>
    );
};

export default FindPetsDetail;