import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';
import { Card, Spinner, Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import slugify from 'slugify';
import './PetDetail.css'; // Import CSS for responsive layout
import { Breadcrumb } from 'react-bootstrap';



import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';


import { faFacebook, faTwitter, faYoutube, faInstagram, faPinterest } from '@fortawesome/free-brands-svg-icons';



function PetDetail() {
    const { id, slug } = useParams(); // Get the ID and slug from the URL params
    const [pet, setPet] = useState(null); // State to hold the pet details
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state
    const { lang } = useParams();
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPetDetails = async () => {
            try {
                const response = await fetch(
                    `https://us-central1-petshome-da28b.cloudfunctions.net/api/public/pets/ad?id=${id}`
                );
                const data = await response.json();

                // If the API call was successful but pet data is missing or invalid, redirect to the home page.
                if (response.ok && data) {
                    // For example, if title is a required field and it's missing, consider it invalid.
                    if (!data.title) {
                        navigate('/', { replace: true });
                        return;
                    }
                    setPet(data);
                } else {
                    // On error from API or if data is missing, redirect to home.
                    navigate('/', { replace: true });
                    return;
                }
            } catch (err) {
                // In case of fetch error, redirect to home.
                navigate('/', { replace: true });
                return;
            } finally {
                setLoading(false);
            }
        };

        fetchPetDetails();
    }, [id, navigate]);

    if (loading) {
        return (
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        );
    }

    // If pet data is not available after loading (though redirection should occur), return null.
    if (!pet) {
        return null;
    }

    // Destructure pet object with potential new location fields.
    const {
        title,
        pet_breed,
        main_image,
        author_country_code,
        asking_price,
        description,
        countryName,
        region,
        city,
    } = pet;

    // Build a location string if any location data is provided.
    const locationString =
        countryName || region || city
            ? `${city ? city + ', ' : ''}${region ? region + ', ' : ''}${countryName ? countryName : ''
                }`.replace(/,\s*$/, '')
            : '';

    // Build the dynamic page title. If location info exists, include it.
    const pageTitle = locationString
        ? `${title} - ${locationString} | Pets Home App`
        : `${title} | Pets Home App`;

    // Build a canonical URL path using the new location fields if all are available; otherwise, use the existing location.pathname.
    const canonicalPath =
        countryName && region && city
            ? `/${slugify(countryName, { lower: true })}/${slugify(region, { lower: true })}/${slugify(city, { lower: true })}/${slug}`
            : location.pathname;

    return (
        <>
            <Helmet>
                <link rel="canonical" href={`https://www.petshome.app${canonicalPath}${location.search}`} />
                <title>{pageTitle}</title>
                <meta name="description" content={description || 'No description available.'} />
                {/* Open Graph Meta Tags */}
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description || 'No description available.'} />
                {main_image && <meta property="og:image" content={main_image} />}
                <meta property="og:type" content="article" />
                <meta property="og:url" content={`https://www.petshome.app${canonicalPath}`} />
                {/* Twitter Card Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description || 'No description available.'} />
                {main_image && <meta name="twitter:image" content={main_image} />}
                {/* Geo Meta Tags for enhanced location SEO */}
                {countryName && <meta name="geo.country" content={countryName} />}
                {region && <meta name="geo.region" content={region} />}
                {city && <meta name="geo.placename" content={city} />}
            </Helmet>


            <Container className="PetDetail container-fluid">
                <Breadcrumb className="pet-breadcrumb">
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/${lang}` }}>
                        Home
                    </Breadcrumb.Item>

                    {countryName && countryName.trim() && (
                        <Breadcrumb.Item
                            linkAs={Link}
                            linkProps={{ to: `/${lang}/pet-classified-ads/${slugify(countryName, { lower: true })}` }}
                        >
                            {countryName}
                        </Breadcrumb.Item>
                    )}

                    {region && region.trim() && (
                        <Breadcrumb.Item
                            linkAs={Link}
                            linkProps={{
                                to: `/${lang}/pet-classified-ads/${slugify(countryName, { lower: true })}/${slugify(region, { lower: true })}`
                            }}
                        >
                            {region}
                        </Breadcrumb.Item>
                    )}

                    {city && city.trim() && (
                        <Breadcrumb.Item
                            linkAs={Link}
                            linkProps={{
                                to: `/${lang}/pet-classified-ads/${slugify(countryName, { lower: true })}/${slugify(region, { lower: true })}/${slugify(city, { lower: true })}`
                            }}
                        >
                            {city}
                        </Breadcrumb.Item>
                    )}

                    <Breadcrumb.Item active>{title}</Breadcrumb.Item>
                </Breadcrumb>
                <header className="p-2 text-center">
                    {/* Primary H1 for SEO: Use the pet title with location info */}
                    <h1 className="display-8 text-dark">
                        {title}
                        {locationString ? ` - ${locationString}` : ''}
                    </h1>
                    {/* Optionally add a generic tagline for clarity */}
                    <Container className="container-fluid p-4">
                        <Row>

                            <Col md={12} className="p-4">


                                <h5 className="text-center font-weight-bold">{t('global.h5_dwonload')}</h5>
                                <div className="d-flex justify-content-center">
                                    <a href="https://play.google.com/store/apps/details?id=com.app.petshome" target="_blank" rel="noopener noreferrer" className="mr-3" title="Download Pets Home App from Google Play Store">
                                        <img style={{ width: '180px', height: 'auto', marginRight: '10px' }} src="/google-play-badge.svg" alt="Download Pets Home App on Google Play" />
                                    </a>
                                    <a href="https://apps.apple.com/app/apple-store/id1549828591?pt=581834&ct=desktop&mt=8" target="_blank" rel="noopener noreferrer" title="Download Pets Home App from Apple App Store">
                                        <img style={{ width: '180px', height: 'auto' }} src="/app-store-badge.svg" alt="Download Pets Home App on App Store" />
                                    </a>
                                </div>
                                <div className="d-flex justify-content-center">
                                    {/* <small> {t('rated')} </small> */}
                                    <div>
                                        <FontAwesomeIcon icon={faStar} color="gold" />
                                        <FontAwesomeIcon icon={faStar} color="gold" />
                                        <FontAwesomeIcon icon={faStar} color="gold" />
                                        <FontAwesomeIcon icon={faStar} color="gold" />
                                        <FontAwesomeIcon icon={faStar} color="gold" />
                                    </div>
                                </div>


                            </Col>
                        </Row>
                    </Container>
                </header>

                <Card className="pet-card" style={{ margin: '20px auto' }}>
                    {main_image && (
                        <Card.Img
                            variant="top"
                            src={main_image}
                            alt={`Image of ${pet_breed}`}
                            className="pet-image"
                        />
                    )}
                    <Card.Body>
                        {/* If you already have an H1 in the header, you might use an H2 for additional titles. */}
                        <h2 className="pet-title">{title}</h2>
                        <Card.Text>
                            <strong>Breed:</strong> {pet_breed || 'Unknown'}
                        </Card.Text>
                        {locationString && (
                            <Card.Text>
                                <strong>Location:</strong> {locationString}
                            </Card.Text>
                        )}
                        <Card.Text>
                            <strong>Description:</strong> {description || 'No description available.'}
                        </Card.Text>
                        <Card.Text>
                            <strong>Rehome Fee:</strong> ${asking_price}
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Container>
        </>
    );
}

export default PetDetail;
