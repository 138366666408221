import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';



const BreedDetector = () => {

    const [selectedImage, setSelectedImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const { t } = useTranslation();




    return (

        <>
            <Helmet>
                <title>{t('breed.meta_title')}</title>
                <meta name="description" content={t('advisor.meta_description')} />
                <meta name="keywords" content={t('advisor.meta_keywords')} />
            </Helmet>

            <Container>
                <Row>
                    <Col md={6} className="p-4">
                        <h2 className="my-4">{t('advisor.h1')} </h2>
                        <p className="text-muted">{t('advisor.description')}</p>
                        <div className="center text-center">
                            <img
                                style={{ maxWidth: '300px', height: 'auto' }}
                                className="img-fluid rounded mb-4 center"
                                src="/assets/pet-advisor.jpg"
                                alt="Rehome Pets Near You | Find Loving Homes for Dogs, Cats, and More | Pets Home App"
                            />

                        </div>

                        <p className="">{t('global.experince')}</p>
                        <p className='text-center font-weight-bold text-uppercase'>{t('global.h5_dwonload')}</p>
                        <div className="d-flex justify-content-center">
                            <a style={{ width: '130px', height: 'auto' }} href="https://play.google.com/store/apps/details?id=com.app.petshome" target="_blank" rel="noopener noreferrer" className="mr-3"><img src="/google-play-badge.svg" alt="Google Play" /></a>
                            <a style={{ width: '130px', height: 'auto' }} href="https://apps.apple.com/us/app/pets-home-app/id1549828591?pt=581834&ct=desktop&mt=8" target="_blank" rel="noopener noreferrer"><img src="/app-store-badge.svg" alt="App Store" /></a>
                        </div>

                        <div className="d-flex justify-content-center">
                            <img style={{ width: '130px', height: 'auto' }} src="/android-adobe-express-qr-code.png" alt="Android QR Code" className="mr-3" />
                            <img style={{ width: '130px', height: 'auto' }} src="/ios-express-qr-code.png" alt="iOS QR Code" />
                        </div>
                        <p className="text-center mt-3">{t('global.qr_code_p')} </p>
                    </Col>

                    <Col md={6} className="p-4">




                        <img
                            style={{ maxWidth: '300px', height: 'auto' }}
                            className="img-fluid rounded mb-4 center"
                            src="/assets/web/pet-advisor.png"
                            alt="Rehome Pets Near You | Find Loving Homes for Dogs, Cats, and More | Pets Home App"
                        />
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default BreedDetector;
