import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Form, Button, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faFilter, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Link, useParams } from 'react-router-dom';
import './FindPets.css';
import { useTranslation } from 'react-i18next';
import slugify from 'slugify';
import { Helmet } from 'react-helmet-async';
import LocationLinks from './LocationLinks';

function FindPets() {
    const [petAds, setPetAds] = useState([]);
    const [filteredPetAds, setFilteredPetAds] = useState([]);
    const [loading, setLoading] = useState(true);
    const [countries, setCountries] = useState([]);
    const [regions, setRegions] = useState([]);
    const [cities, setCities] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedRegion, setSelectedRegion] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [showFilters, setShowFilters] = useState(false);
    const { lang } = useParams();
    const { t } = useTranslation();

    // Fetch the pet ads on component mount
    useEffect(() => {
        setLoading(true);
        fetch('https://us-central1-petshome-da28b.cloudfunctions.net/api/public/pets/all')
            .then((response) => response.json())
            .then((data) => {
                setPetAds(data);
                setFilteredPetAds(data);

                // Extract unique location data for filters
                const uniqueCountries = [...new Set(data.filter(ad => ad.countryName).map(ad => ad.countryName))].sort();
                setCountries(uniqueCountries);

                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching pet listings:', error);
                setLoading(false);
            });
    }, []);

    // Update regions when country selection changes
    useEffect(() => {
        if (selectedCountry) {
            const countryPets = petAds.filter(ad => ad.countryName === selectedCountry);
            const uniqueRegions = [...new Set(countryPets.filter(ad => ad.region).map(ad => ad.region))].sort();
            setRegions(uniqueRegions);
            setSelectedRegion('');
            setSelectedCity('');
            setCities([]);
        } else {
            setRegions([]);
            setSelectedRegion('');
            setSelectedCity('');
            setCities([]);
        }
    }, [selectedCountry, petAds]);

    // Update cities when region selection changes
    useEffect(() => {
        if (selectedRegion) {
            const regionPets = petAds.filter(ad =>
                ad.countryName === selectedCountry && ad.region === selectedRegion
            );
            const uniqueCities = [...new Set(regionPets.filter(ad => ad.city).map(ad => ad.city))].sort();
            setCities(uniqueCities);
            setSelectedCity('');
        } else {
            setCities([]);
            setSelectedCity('');
        }
    }, [selectedRegion, selectedCountry, petAds]);

    // Apply filters when selections change
    useEffect(() => {
        let filtered = [...petAds];

        if (selectedCountry) {
            filtered = filtered.filter(ad => ad.countryName === selectedCountry);
        }

        if (selectedRegion) {
            filtered = filtered.filter(ad => ad.region === selectedRegion);
        }

        if (selectedCity) {
            filtered = filtered.filter(ad => ad.city === selectedCity);
        }

        setFilteredPetAds(filtered);
    }, [selectedCountry, selectedRegion, selectedCity, petAds]);

    // Handle filter changes
    const handleCountryChange = (e) => {
        setSelectedCountry(e.target.value);
    };

    const handleRegionChange = (e) => {
        setSelectedRegion(e.target.value);
    };

    const handleCityChange = (e) => {
        setSelectedCity(e.target.value);
    };

    // Clear all filters
    const clearFilters = () => {
        setSelectedCountry('');
        setSelectedRegion('');
        setSelectedCity('');
        setFilteredPetAds(petAds);
    };

    // Toggle filter visibility on mobile
    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };

    // Generate each advert card
    const generateAdvertCard = (ad, key) => {
        const {
            id,
            title,
            pet_breed,
            main_image,
            author_country_code,
            asking_price,
            description,
            countryName,
            region,
            city,
        } = ad;

        const flagUrl = `https://flagcdn.com/24x18/${author_country_code?.toLowerCase() || 'xx'}.png`;
        const petBreed = pet_breed || 'Mixed Breed';

        // Build the pet slug based on pet breed and title
        const slug =
            `${pet_breed || 'pet'}-${title}`
                .toLowerCase()
                .normalize('NFD') // Normalize accents
                .replace(/[\u0300-\u036f]/g, '') // Remove accents
                .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with hyphens
                .replace(/^-+|-+$/g, ''); // Trim hyphens

        // Build a location string (for display) if any location data is provided
        const locationString =
            countryName || region || city
                ? `${city ? city + ', ' : ''}${region ? region + ', ' : ''}${countryName ? countryName : ''}`.replace(
                    /,\s*$/,
                    ''
                )
                : '';

        // Build the ad title. If location info exists, include it.
        const adTitle = locationString ? `${title} - ${locationString}` : title;

        // Build the detail page URL.
        // If location details exist, include them in the URL; otherwise use the fallback.
        const detailUrl =
            countryName && countryName.trim() &&
                region && region.trim() &&
                city && city.trim()
                ? `/${lang}/pet-classified-ads/${slugify(countryName, { lower: true })}/${slugify(region, { lower: true })}/${slugify(city, { lower: true })}/${slug}/${id}`
                : `/${lang}/${slug}/${id}`;

        return (
            <Card key={key} className="pet-card">
                <div style={{ position: 'relative' }}>
                    {main_image && (
                        <Card.Img
                            variant="top"
                            src={main_image}
                            style={{ objectFit: 'cover', height: '200px' }}
                            alt={`Image of ${petBreed}`}
                        />
                    )}
                    {author_country_code && (
                        <img
                            src={flagUrl}
                            alt={`Flag of ${author_country_code}`}
                            style={{
                                position: 'absolute',
                                top: '8px',
                                right: '8px',
                                width: '24px',
                                height: '18px',
                                borderRadius: '3px',
                                boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)',
                            }}
                        />
                    )}
                </div>
                <Card.Body>
                    {/* Clickable Title */}
                    <Link to={detailUrl} className="card-title-link text-decoration-none">
                        <Card.Title style={{ fontSize: '16px', color: '#007bff' }}>
                            {adTitle}
                        </Card.Title>
                    </Link>
                    <Card.Text style={{ fontSize: '14px' }}>
                        <strong>{petBreed}</strong>
                    </Card.Text>
                    <Card.Text
                        style={{
                            fontSize: '12px',
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                        }}
                    >
                        {description}
                    </Card.Text>
                    <Card.Text style={{ fontSize: '14px' }}>
                        <strong>Price / Rehome Fee:</strong> ${asking_price}
                    </Card.Text>
                    {locationString && (
                        <div style={{ fontSize: '12px', color: '#666' }}>
                            <i className="fas fa-map-marker-alt mr-1"></i> {locationString}
                        </div>
                    )}
                </Card.Body>
            </Card>
        );
    };

    return (
        <>
            <Helmet>
                <title>{t('find.meta_title')}</title>
                <meta name="description" content={t('find.meta_description')} />
                <meta name="keywords" content={t('find.meta_keywords')} />
            </Helmet>

            <div className="FindPets">
                <Container className="p-4">
                    <Row>
                        <Col md={4} className="p-4">
                            <h2 className="my-4">{t('find.h1')}</h2>
                            <p className="text-muted">{t('find.description')}</p>


                            <div className="text-center d-none d-md-block">
                                <Link
                                    className="btn btn-success text-dark btn-lg my-2"
                                    aria-label="Post Your Free Pet Ad Now"
                                    to={`/${lang}/rehome-sell-pets`}
                                    style={{ backgroundColor: '#00FFFF', color: '#38C1D8' }}
                                >
                                    <i className="fas fa-plus"></i> {t("Post Your Free Pet Ad Now")}
                                </Link>
                            </div>

                            <h5 className="text-center font-weight-bold mt-4">{t('global.h5_dwonload')}</h5>
                            <div className="d-flex justify-content-center">
                                <a
                                    href="https://play.google.com/store/apps/details?id=com.app.petshome"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="mr-3"
                                    title="Download Pets Home App from Google Play Store"
                                >
                                    <img
                                        style={{ width: '180px', height: 'auto', marginRight: '10px' }}
                                        src="/google-play-badge.svg"
                                        alt="Download Pets Home App on Google Play"
                                    />
                                </a>
                                <a
                                    href="https://apps.apple.com/app/apple-store/id1549828591?pt=581834&ct=desktop&mt=8"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="Download Pets Home App from Apple App Store"
                                >
                                    <img
                                        style={{ width: '180px', height: 'auto' }}
                                        src="/app-store-badge.svg"
                                        alt="Download Pets Home App on App Store"
                                    />
                                </a>
                            </div>
                            <div className="d-flex justify-content-center">
                                <div>
                                    <FontAwesomeIcon icon={faStar} color="gold" />
                                    <FontAwesomeIcon icon={faStar} color="gold" />
                                    <FontAwesomeIcon icon={faStar} color="gold" />
                                    <FontAwesomeIcon icon={faStar} color="gold" />
                                    <FontAwesomeIcon icon={faStar} color="gold" />
                                </div>
                            </div>
                            <div className="d-flex justify-content-center mt-4">
                                <img
                                    style={{ width: '130px', height: 'auto' }}
                                    src="/android-adobe-express-qr-code.png"
                                    alt="Android QR Code"
                                    className="mr-3"
                                />
                                <img
                                    style={{ width: '130px', height: 'auto' }}
                                    src="/ios-express-qr-code.png"
                                    alt="iOS QR Code"
                                />
                            </div>
                            <p className="text-center mt-3 d-none d-md-block">
                                {t('Scan the QR codes below for quick access:')}
                            </p>
                        </Col>

                        <Col md={8} className="p-4">

                            {/* Location Filters */}
                            <div className="d-md-block d-none">
                                <Card className="mb-4 filter-card">
                                    {/* <Card.Header className="bg-light">
                                        <h5 className="mb-0">
                                            <FontAwesomeIcon icon={faFilter} className="mr-2" />
                                            {t('Location Filters')}
                                        </h5>
                                    </Card.Header> */}
                                    <Card.Body>
                                        <Form>
                                            <Form.Group>
                                                <Form.Label>{t('Country')}</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    value={selectedCountry}
                                                    onChange={handleCountryChange}
                                                >
                                                    <option value="">{t('All Countries')}</option>
                                                    {countries.map(country => (
                                                        <option key={country} value={country}>
                                                            {country}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>

                                            {selectedCountry && (
                                                <Form.Group>
                                                    <Form.Label>{t('Region/State')}</Form.Label>
                                                    <Form.Control
                                                        as="select"
                                                        value={selectedRegion}
                                                        onChange={handleRegionChange}
                                                    >
                                                        <option value="">{t('All Regions')}</option>
                                                        {regions.map(region => (
                                                            <option key={region} value={region}>
                                                                {region}
                                                            </option>
                                                        ))}
                                                    </Form.Control>
                                                </Form.Group>
                                            )}

                                            {selectedRegion && (
                                                <Form.Group>
                                                    <Form.Label>{t('City')}</Form.Label>
                                                    <Form.Control
                                                        as="select"
                                                        value={selectedCity}
                                                        onChange={handleCityChange}
                                                    >
                                                        <option value="">{t('All Cities')}</option>
                                                        {cities.map(city => (
                                                            <option key={city} value={city}>
                                                                {city}
                                                            </option>
                                                        ))}
                                                    </Form.Control>
                                                </Form.Group>
                                            )}

                                            {(selectedCountry || selectedRegion || selectedCity) && (
                                                <Button
                                                    variant="outline-secondary"
                                                    size="sm"
                                                    onClick={clearFilters}
                                                    className="mt-2"
                                                >
                                                    <FontAwesomeIcon icon={faTimes} className="mr-1" />
                                                    {t('Clear Filters')}
                                                </Button>
                                            )}
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </div>

                            {/* Mobile filters toggle button */}
                            <div className="d-md-none mb-3">
                                <Button
                                    variant="outline-primary"
                                    onClick={toggleFilters}
                                    className="w-100"
                                >
                                    <FontAwesomeIcon icon={faFilter} className="mr-2" />
                                    {showFilters ? t('Hide Filters') : t('Show Location Filters')}
                                </Button>
                            </div>

                            {/* Mobile filters (collapsible) */}
                            {showFilters && (
                                <Card className="mb-4 d-md-none">
                                    <Card.Body>
                                        <Form>
                                            <Form.Group>
                                                <Form.Label>{t('Country')}</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    value={selectedCountry}
                                                    onChange={handleCountryChange}
                                                >
                                                    <option value="">{t('All Countries')}</option>
                                                    {countries.map(country => (
                                                        <option key={country} value={country}>
                                                            {country}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>

                                            {selectedCountry && (
                                                <Form.Group>
                                                    <Form.Label>{t('Region/State')}</Form.Label>
                                                    <Form.Control
                                                        as="select"
                                                        value={selectedRegion}
                                                        onChange={handleRegionChange}
                                                    >
                                                        <option value="">{t('All Regions')}</option>
                                                        {regions.map(region => (
                                                            <option key={region} value={region}>
                                                                {region}
                                                            </option>
                                                        ))}
                                                    </Form.Control>
                                                </Form.Group>
                                            )}

                                            {selectedRegion && (
                                                <Form.Group>
                                                    <Form.Label>{t('City')}</Form.Label>
                                                    <Form.Control
                                                        as="select"
                                                        value={selectedCity}
                                                        onChange={handleCityChange}
                                                    >
                                                        <option value="">{t('All Cities')}</option>
                                                        {cities.map(city => (
                                                            <option key={city} value={city}>
                                                                {city}
                                                            </option>
                                                        ))}
                                                    </Form.Control>
                                                </Form.Group>
                                            )}

                                            {(selectedCountry || selectedRegion || selectedCity) && (
                                                <Button
                                                    variant="outline-secondary"
                                                    size="sm"
                                                    onClick={clearFilters}
                                                    className="mt-2"
                                                >
                                                    <FontAwesomeIcon icon={faTimes} className="mr-1" />
                                                    {t('Clear Filters')}
                                                </Button>
                                            )}
                                        </Form>
                                    </Card.Body>
                                </Card>
                            )}

                            {/* Active filters display */}
                            {(selectedCountry || selectedRegion || selectedCity) && (
                                <div className="mb-3">
                                    <strong>{t('Active Filters')}: </strong>
                                    {selectedCountry && (
                                        <Badge pill variant="info" className="mr-2 p-2">
                                            {selectedCountry} <FontAwesomeIcon icon={faTimes} onClick={() => setSelectedCountry('')} style={{ cursor: 'pointer' }} />
                                        </Badge>
                                    )}
                                    {selectedRegion && (
                                        <Badge pill variant="info" className="mr-2 p-2">
                                            {selectedRegion} <FontAwesomeIcon icon={faTimes} onClick={() => setSelectedRegion('')} style={{ cursor: 'pointer' }} />
                                        </Badge>
                                    )}
                                    {selectedCity && (
                                        <Badge pill variant="info" className="mr-2 p-2">
                                            {selectedCity} <FontAwesomeIcon icon={faTimes} onClick={() => setSelectedCity('')} style={{ cursor: 'pointer' }} />
                                        </Badge>
                                    )}
                                </div>
                            )}

                            {/* Results count */}
                            <div className="mb-3">
                                <p>
                                    {loading
                                        ? t('Loading...')
                                        : t('Showing Recently Posted {{count}} results. Please download mobile app to see full listings.', { count: filteredPetAds.length })}
                                </p>
                            </div>

                            {/* Mobile post ad button */}
                            <div className="text-center d-md-none mb-4">
                                <Link
                                    className="btn btn-success text-dark btn-lg"
                                    aria-label="Post Your Free Pet Ad Now"
                                    to={`/${lang}/rehome-sell-pets`}
                                    style={{ backgroundColor: '#00FFFF', color: '#38C1D8' }}
                                >
                                    <i className="fas fa-plus"></i> {t("Post Your Free Pet Ad Now")}
                                </Link>
                            </div>

                            {/* Pet listings */}
                            <div className="pet-listing">
                                {loading ? (
                                    <p>{t('Loading pet listings...')}</p>
                                ) : filteredPetAds.length > 0 ? (
                                    filteredPetAds.map((ad, index) => generateAdvertCard(ad, index))
                                ) : (
                                    <div className="text-center p-4">
                                        <p>{t('No pet listings available for the selected filters.')}</p>
                                        {(selectedCountry || selectedRegion || selectedCity) && (
                                            <Button
                                                variant="primary"
                                                onClick={clearFilters}
                                            >
                                                {t('Clear Filters')}
                                            </Button>
                                        )}
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default FindPets;